
import {
  ref,
  onMounted,
  getCurrentInstance,
  computed,
  reactive
} from 'vue'
import {
  globalSettingUseCase,
  globalSettingOptionUseCase,
  receiveItemFileUseCase
} from '@/domain/usecase'
import {
  useRoute
} from 'vue-router'
import {
  useStore
} from 'vuex'
import {
  cloneDeep
} from 'lodash'
import {
  environment
} from '@/utils'
import { compressImage } from '@/plugins/compressor'
import { useDropzone } from 'vue3-dropzone'
import { takePhoto } from '@/plugins/system/camera'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

const modules = 'voucher'

export default {
  setup() {
    const store = useStore()
    const app = getCurrentInstance()
    const linkEnv = ref(environment.getApiBase())
    const imgTemp = ref(null) as any
    const dataTemp = reactive({
      whatsapp: ''
    })
    const {
      $confirm,
      $toast,
      $strInd,
      $numbers
    } = app!.appContext.config.globalProperties
    const data = ref([]) as any
    const dataset = ref() as any
    const listAuthReport = ref([]) as any
    const route = useRoute()
    const selectedCode = ref(null) as any
    const sectionTitle = ref([
      {
        key: 1,
        title: 'Pengaturan Pengiriman'
      },
      {
        key: 2,
        title: 'Pengaturan Kurir'
      },
      {
        key: 3,
        title: 'Pengaturan Jadwal'
      },
      {
        key: 4,
        title: 'Pengaturan OTP'
      },
      {
        key: 5,
        title: 'Pengaturan Pembayaran'
      },
      {
        key: 6,
        title: 'Pengaturan Lain-lain'
      },
      {
        key: 7,
        title: 'Pengaturan Poin'
      }
    ])

    const getAllData = async () => {
      store.dispatch('showLoading')
      const response = await globalSettingUseCase.getAll()
      // console.log(response)
      data.value = response.result
      data.value.forEach((element: any) => {
        if (element.Type === 'NUM') {
          element.Value = Number(element.Value)
        } else if (element.Code === 'AuthReport' || element.Code === 'PeriodeKomisiKurir') {
          element.Value = element.Value.split(',')
          element.OptionValue = element.OptionValue.split(',')
          // console.log('optionvalue', element.OptionValue)
        // } else if (element.Code === 'PeriodeKomisiKurir') {
        //   const tempAttribute2 = element.Attribute2.replace('@Array', '').replace('[', '').replace(']', '').replace(/"/g, '')
        //   element.Attribute2 = tempAttribute2.split(',')
        } else if (element.Code === 'Banner1') {
          imgTemp.value = element.Value
        } else if (element.Code === 'AdminWhatsappNumber') {
          dataTemp.whatsapp = element.Value
        }
        element.defaultValue = element.Value
        element.disabled = true
        // console.log(element)
      })
      console.log('data', data.value)
      // eslint-disable-next-line no-use-before-define
      grouping(data.value)
      // data.value.sort(compare)
      // eslint-disable-next-line no-use-before-define
      // mappingData(data.value)
      // console.log(imgTemp.value)
      store.dispatch('hideLoading')
    }

    const compare = (after: any, before: any) => {
      // console.log('a', after)
      // console.log('b', before)
      if (after.GroupOrder < before.GroupOrder) {
        return -1
      }
      if (after.GroupOrder > before.GroupOrder) {
        return 1
      }
      return 0
    }

    const grouping = (data: any) => {
      const datasetTemp = data.reduce((x: any, y: any) => {
        x[y.GroupCode] = x[y.GroupCode] || []
        x[y.GroupCode].push(y)
        return x
      }, Object.create(null))

      // console.log('cobaBefore', datasetTemp)

      dataset.value = Object.keys(datasetTemp).map((key) => [key, datasetTemp[key]])
      // console.log('cobaTemp', dataset)

      dataset.value.forEach((cobaEl: any) => {
        // console.log('id', cobaEl[0])
        // eslint-disable-next-line eqeqeq
        const titleTemp = sectionTitle.value.find((data: any) => data.key == cobaEl[0])
        cobaEl.push(titleTemp?.title)
        // console.log('temp', titleTemp)
        cobaEl[1].sort(compare)
      })
      console.log('cobaAfter', dataset.value)
    }

    const rules = {
      whatsapp: {
        required: helpers.withMessage('Nama klaim harus diisi', required)
      }
    }

    const v$ = useVuelidate(rules, dataTemp)

    const dataForm = reactive({
      title: computed({
        get: () => store.state[modules].Nama,
        set: (val) => {
          store.dispatch(`${modules}/setNama`, val)
        }
      }),
      description: computed({
        get: () => store.state[modules].Keterangan,
        set: (val) => {
          store.dispatch(`${modules}/setDescription`, val)
        }
      }),
      targetUser: computed({
        get: () => store.state[modules].GenerateRefCode,
        set: (val) => {
          if (val) {
            store.dispatch(`${modules}/setKode`, null)
          }
          store.dispatch(`${modules}/setGenerateReferalCode`, val)
        }
      }),
      priceType: computed({
        get: () => store.state[modules].IsMoney,
        set: (val) => {
          store.dispatch(`${modules}/setIsMoney`, val)
        }
      }),
      kode: computed({
        get: () => store.state[modules].Kode,
        set: (val) => {
          store.dispatch(`${modules}/setKode`, val)
        }
      }),
      maxPotongan: computed({
        get: () => store.state[modules].MaxPotongan,
        set: (val) => {
          store.dispatch(`${modules}/setMaxPotongan`, val)
        }
      }),
      discount: computed({
        get: () => store.state[modules].Discount,
        set: (val) => {
          store.dispatch(`${modules}/setDiscount`, val)
        }
      }),
      qty: computed({
        get: () => store.state[modules].Qty,
        set: (val) => {
          store.dispatch(`${modules}/setQty`, val)
        }
      }),
      useLimit: computed({
        get: () => store.state[modules].UseLimit,
        set: (val) => {
          if (val !== null) {
            store.dispatch(`${modules}/setUseLimit`, val)
          } else {
            store.dispatch(`${modules}/setUseLimit`, 0)
          }
        }
      }),
      poin: computed({
        get: () => store.state[modules].Poin,
        set: (val) => {
          if (val !== null) {
            store.dispatch(`${modules}/setPoin`, val)
          } else {
            store.dispatch(`${modules}/setPoin`, 0)
          }
        }
      }),
      isActive: computed({
        get: () => store.state[modules].IsActive,
        set: (val) => {
          store.dispatch(`${modules}/setIsActive`, val)
        }
      }),
      isHidden: computed({
        get: () => store.state[modules].IsHidden,
        set: (val) => {
          store.dispatch(`${modules}/setIsHidden`, val)
        }
      })
    })

    const OptionBatasOrderCustomer = [{
      id: -1,
      name: 'di buka semua'
    },
    {
      id: 0,
      name: 'hari ini'
    },
    {
      id: 1,
      name: 'Hari ini dan besok'
    },
    {
      id: 2,
      name: 'Hari ini, besok dan besok lusa.'
    }
    ]
    const checkResponse = (response: any) => {
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        getAllData()
        $toast.add({
          severity: 'success',
          detail: $strInd.toast.successSubmit,
          group: 'bc',
          life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    const getOptionGlobaSetting = async () => {
      const response = await globalSettingOptionUseCase.getAll({
        select: '&$select=Id,Nama,OptionId'
      })
      listAuthReport.value = response.result
      listAuthReport.value.forEach((element: any) => {
        element.value = element.OptionId
        element.label = element.Nama
      })
    }

    const image = computed({
      get: () => store.state[modules].Gambar,
      set: (val) => {
        store.dispatch(`${modules}/setGambar`, val)
      }
    })

    const resetImage = () => {
      image.value.src = null
      image.value.image = null
      imgTemp.value = null
    }

    const pushTempImage = async (item: any, from: any = '') => {
      let file = item
      if (from === 'camera') {
        // file = new File([item], 'Name', {
        //   lastModified: moment().unix(),
        //   type: item.type,
        // })
        item = item.image
      } else {
        // eslint-disable-next-line no-await-in-loop
        file = await compressImage(file)
        file = new File([file], file.name)
      }
      const img = image.value as any
      resetImage()
      if (img.src === null) {
        image.value = {
          src: URL.createObjectURL(item),
          image: file
        }
      }
    }

    function onDrop(acceptFiles: any, rejectReasons: any) {
      if (acceptFiles[0].type.includes('image')) {
        pushTempImage(acceptFiles[0])
      } else {
        $toast.add({
          severity: 'error', detail: 'Hanya support tipe file gambar (png/jpg/jpeg)', group: 'bc', life: 1500
        })
      }
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop })
    const showCamera = async () => {
      const response = await takePhoto() as any
      pushTempImage(response, 'camera')
    }

    const proccessUploadFile = async () => {
      const item = image.value
      // console.log('item', item.image)
      if (item.src !== null && typeof item.image.name === 'string') {
        const formDataPict = new FormData()
        // const senderId = props.id
        const img = item.image
        // formData.append('IdVoucher', idVoucher)
        formDataPict.append('file', img)
        // formData.append('IdVoucher', idVoucher)
        const response = await receiveItemFileUseCase.submitFile('banner', formDataPict)
        // console.log('response upload =>', response)
        if (response.error) {
          $toast.add({
            severity: 'error', detail: response.message, group: 'bc', life: 3000
          })
          return null
        }
        return response.result
      } return null
    }

    const processStoreData = async () => {
      store.dispatch('showLoading')
      const resUpload = await proccessUploadFile() as any
      const dataSend = cloneDeep(data.value)
      console.log('dataSend', dataSend)
      dataSend.forEach((element: any) => {
        if (element.Code === 'Banner1') {
          element.Value = resUpload === null ? 'null' : resUpload.Filename
        }
        element.Value = element.Value.toString()
      })
      const response = await globalSettingUseCase.submitAllData(dataSend)
      checkResponse(response)
    }

    const submitData = () => {
      console.log('dataForm', dataTemp)
      $confirm.require({
        header: 'Edit Pengaturan Global',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          processStoreData()
        }
      })
    }

    const dataSource = ref([] as any)
    const mappingData = (value: any) => {
      dataSource.value = [
        {
          name: 'NUM'
        },
        {
          name: 'CMB'
        },
        {
          name: 'STRA'
        }
      ]
      // for (let index = 0; index < 5; index++) {
      value.forEach((ele: any) => {
        if (ele.Attribute1 === 'NUM') {
          dataSource.value[0].data = ele
        } else if (ele.Attribute1 === 'CMB') {
          dataSource.value[1].data = ele
        } else if (ele.Attribute1 === 'STRA') {
          dataSource.value[2].data = ele
        }
      })
      console.log('datasos', dataSource.value)
    }

    const editAct = (item: any) => {
      if (selectedCode.value) {
        // eslint-disable-next-line array-callback-return
        dataset.value.map((d: any) => d[1].map((dtl: any) => {
          // console.log('dtl', dtl)
          if (dtl.Code === selectedCode.value.Code) {
            dtl.Value = dtl.defaultValue
            dtl.disabled = true
          }
        }))
        selectedCode.value = null
        selectedCode.value = item
        item.disabled = false
      } else {
        item.disabled = false
        selectedCode.value = item
      }
    }

    const cancelEditAct = (item: any) => {
      console.log('data', dataset.value)
      // eslint-disable-next-line array-callback-return
      dataset.value.map((d: any) => d[1].map((dtl: any) => {
        if (dtl.Code === item.Code) {
          console.log('dtl', dtl)
          dtl.Value = dtl.defaultValue
          dtl.disabled = true
        }
      }))
      selectedCode.value = null
      console.log('data', dataset)
    }

    const processSubmitAct = async (data: any) => {
      store.dispatch('showLoading')
      const payload = {
        Id: data.Id,
        Value: data.Value,
      }
      const response = await globalSettingUseCase.submitData(data.Id, payload)
      checkResponse(response)
      selectedCode.value = null
      store.dispatch('hideLoading')
    }

    const submitEditAct = (data: any) => {
      $confirm.require({
        header: `Edit ${data.Name}`,
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          processSubmitAct(data)
        }
      })
    }

    onMounted(() => {
      getAllData()
      // getOptionGlobaSetting()
    })
    const ValMaxPersen = (e: any) => {
      if (e.target.value > 100) {
        $toast.add({
          severity: 'error',
          detail: 'maksimal nilai 100%',
          group: 'bc',
          life: 3000
        })
        e.target.value = 0
      }
    }
    return {
      data,
      submitData,
      route,
      listAuthReport,
      OptionBatasOrderCustomer,
      ValMaxPersen,
      showCamera,
      getRootProps,
      getInputProps,
      imgTemp,
      linkEnv,
      image,
      resetImage,
      v$,
      dataset,
      selectedCode,
      cancelEditAct,
      editAct,
      submitEditAct
    }
  },
}
